<script>
	import Image from '$lib/Components/Layout/Image/Image.svelte';
	import Section from '$lib/Components/Layout/Section/Section.svelte';

	import { links, social } from './links';
</script>

<Section backgroundColor="grey-dark" class="max-md:pt-0 print:hidden">
	<footer class="w-full">
		{#if links}
			<nav aria-label="Footer" class="mb-16">
				<ul class="grid w-full grid-flow-col justify-between pl-0">
					{#each links as link}
						<li class="link-section">
							<a class="!text-white" href={link.href}>{link.name}</a>
							{#if link.children}
								<ul class="mt-4 pl-0">
									{#each link.children as child}
										<li>
											<a href={child.href}>{child.name}</a>
										</li>
									{/each}
								</ul>
							{/if}
						</li>
					{/each}
				</ul>
			</nav>
		{/if}

		<div
			class="bottom-row grid grid-flow-row gap-4 md:grid-flow-col md:justify-between"
		>
			<div class="copyright grid grid-flow-row gap-x-8 md:grid-flow-col">
				<p class="mb-0 p-0 text-sm">
					© Indow Windows {new Date().getFullYear()}
				</p>
				<p class="mb-0 p-0 text-sm">Assembled in Portland, OR</p>
				<p class="mb-0 p-0 text-sm">Patent No. US 9976335</p>
			</div>

			{#if social}
				<ul class="social-links row-start-1 flex-row items-center gap-4 pl-0">
					{#each social as link}
						<li class="!mb-0">
							<a href={link.href} target="_blank" rel="noreferrer">
								<div class="image">
									<Image src={link.img} alt={link.name} />
								</div>
							</a>
						</li>
					{/each}
				</ul>
			{/if}
		</div>
	</footer>
</Section>

<style>
	ul {
		list-style-type: none;
	}
	a {
		font-weight: 500;
		font-size: 0.75rem;
		color: var(--color-indow-grey-light);
		text-decoration: none;
	}

	.copyright p {
		font-weight: 100;
		color: white;
		width: auto;
	}
	.bottom-row {
		display: grid;
		width: 100%;
		align-self: center;
		align-items: flex-end;
	}
	.social-links {
		width: 100%;
		display: flex;
		justify-content: space-evenly;
		column-gap: 0.5re m;
	}
	.social-links a .image {
		width: 100%;
		max-width: 1.5rem;
		height: auto;
	}

	/*Smaller screen styling*/
	@media only screen and (max-width: 768px) {
		footer {
			padding: 1rem 0.5rem;
		}
		nav {
			display: none;
		}

		.social-links a .image {
			width: 100%;
			height: auto;
			max-width: 2rem;
		}
	}
</style>
